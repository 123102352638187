.vis-labelset .vis-label {
  border: 0px solid #f200c9;

  border-bottom: 0px solid #00c3f8;
}


.vis-timeline {
  border: 0px solid white;
  border-radius: 10px;
}

.vis-time-axis {
  color: rgb(188, 0, 0);
}





.vis-foreground .vis-group {
  border-bottom: 0px solid #bfbfbf;
}


.vis-item.vis-range {
  border-radius: 10px;
}


.video {
  background-color: #071D88;
  color: #F7F7F7;
  border-color: transparent;
}

.text {
  background-color: #077488;
  color: #F7F7F7;
  border-color: transparent;
}

.image {
  background-color: #078870;
  color: #F7F7F7;
  border-color: transparent;
}

.audio {
  background-color: #FFA500;
  color: #F7F7F7;
  border-color: transparent;
}









.vis-background {
  background-color: #141725;

}

.currenttime {
  background: rgb(188, 0, 0);
  
}

.vis-time-axis .vis-text {

  color: #3C4369;
  /*left: -12px*/
}

.vis-panel.vis-bottom,
.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top {
  border: 0px solid white;
}

.vis-drag-left {
  background-color: #04104c;
  transform: scale(0.5, 0.8);
  border-radius: 5px;



}

.vis-drag-right {
  background-color: #04104c;
  transform: scale(0.5, 0.8);
  border-radius: 5px;  

}